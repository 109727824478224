// Reset some basic elements
body, h1, h2, h3, h4, h5, h6, p, blockquote, pre, hr, dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

// Basic styling
body {
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

// Set `margin-bottom` to maintain vertical rhythm
h1, h2, h3, h4, h5, h6, p, blockquote, pre, ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit * 0.5;
}

// Images
img {
  max-width: 100%;
  vertical-align: middle;
}

// Lists
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

// Headings
h1, h2, h3, h4, h5, h6 {
  font-weight: $base-font-weight;
}

// Links
a {
  color: #fff;
  text-decoration: underline;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

// Clearfix
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}
