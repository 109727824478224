@charset "utf-8";

// Define defaults for each variable.
$base-font-size:   18px !default;
$base-font-weight: 400 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

// Width of the content area
$on-palm:          600px !default;
$on-laptop:        800px !default;

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials.
@import
  "minima/base",
  "minima/layout"
;
